import ApiService from "@/core/services/api.service";
import Vue from "vue";

let getTimeZoneFormat = (type = 'date') => {
    if (type == 'date_time')
        return 'yyyy-MM-DDThh:mm';
    else
        return 'yyyy-MM-DD';
}
let requestTimeZone = () => {
    return ApiService.get('base/dependency/default-timezone');
}
let getTimeZone = async (type = 'date') => {
    return await requestTimeZone().then((response)=>{
        return Vue.prototype.$moment(response.data.data).format(getTimeZoneFormat(type))

    });
}

export default {getTimeZone};